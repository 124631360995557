.page {
  form {
    legend {
      color: $dlrgColorBlue;
    }

    select,
    textarea,
    input {
      &.form-control {
        border: solid 1px $dlrgColorBlue;
        background-color: $mainColorWhite;

        &:required {
          border-left: $dlrgColorRequired solid 5px !important;
        }
      }
    }

    select,
    input {
      &.form-control {
        min-height: 50px;
      }

      &.form-control-sm {
        min-height: 41px;

        @include media-breakpoint-down(lg) {
          min-height: 38px;
        }
      }
    }

    input[type="file"] {
      &.form-control {
        color: $headerColorGrey;
        background-color: $headerColorLightGrey;
        border: unset;
      }
    }

    .form-check {
      display: flex;

      input {
        &[type="checkbox"],
        &[type="radio"] {
          min-width: 0.8em;
          width: 0.8em;
          height: 0.8em;
          margin-top: 0.9rem;
          margin-right: 0.5rem;
        }
      }
    }

    [class*="label"] {
      padding-top: 7.5px;
    }
  }
}

.acResults {
  ul {
    padding-left: 0.75rem;
    background: $dlrgColorBlue;
    color: $mainColorWhite;

    li {
      list-style: none;
    }
  }
}