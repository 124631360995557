//Bootstrap
@import '../../../node_modules/bootstrap/scss/bootstrap';

//Bootstrap-Table
@import '../../../node_modules/bootstrap-table/src/bootstrap-table';

//Fontawesome Pro
@import '../../../node_modules/@fortawesome/fontawesome-pro/scss/light';
@import '../../../node_modules/@fortawesome/fontawesome-pro/scss/regular';
@import '../../../node_modules/@fortawesome/fontawesome-pro/scss/solid';
@import '../../../node_modules/@fortawesome/fontawesome-pro/scss/duotone';
@import '../../../node_modules/@fortawesome/fontawesome-pro/scss/brands';
@import '../../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome';
$FontAwesomeFamily: "Font Awesome 5 Pro";

//Fullcalendar
@import '../../../node_modules/fullcalendar/main';

//Shariff
@import '../../../node_modules/shariff/dist/shariff.min';

//RangeSlider
@import '../../../node_modules/rangeslider.js/dist/rangeslider';

//Mixin-Style-Sheets
@import 'partials/mediaquerys';
@import 'partials/teaser.scss'; //Als erstes, weil Mixin-Stylesheet!

// neue SCSS-Dateien einbinden
@import '404.scss';
@import 'accordion.scss';
@import 'allgemein.scss';
@import 'button.scss';
@import 'cookie.scss';
@import 'divider.scss';
@import 'dreiSpaltenTextBild.scss';
@import 'fancybox.scss';
@import 'filelist.scss';
@import 'flipCard.scss';
@import 'footer.scss';
@import 'formular.scss';
@import 'fuenfBildText.scss';
@import 'gallery.scss';
@import 'header.scss';
@import 'listen.scss';
@import 'map.scss';
@import 'media.scss';
@import 'modal.scss';
@import 'news.scss';
@import 'etb.scss'; //Bekommt Elemente von News, daher dahinter
@import 'personen.scss';
@import 'publikation.scss';
@import 'seminar.scss';
@import 'shop.scss';
@import 'sitemap.scss';
@import 'slider.scss';
@import 'social-media.scss';
@import 'spaltenelemente.scss';
@import 'spenden.scss';
@import 'sticky.scss';
@import 'suche.scss';
@import 'table.scss';
@import 'teaserbox.scss';
@import 'termin.scss';
@import 'tooltip.scss';
@import 'training.scss';
@import 'trauer.scss';

//jQuery
@import '/global/layout/2019/fancybox/jquery.fancybox.min.css';