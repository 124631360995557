.etb {
  @include teaser()
}

.page { //Übername aus News für Detailbox
  .etb-einsatz {
    @extend .news-detail;
    .etb-banner {
      @extend .news-banner;
    }
  }
}