#amountBox {
  .panel-title {
    color: $dlrgColorBlue !important;
    font-size: 1.8rem;
  }

  input#submitForm {
    background: $dlrgColorBlue;
  }
}

@mixin tickmarks($sideSpacing, $centerMargin) {
  padding: 0 calc($sideSpacing/2);
  margin-left: calc($sideSpacing/2);
  margin-right: calc($sideSpacing/2);
  margin-bottom: calc(-1 * $centerMargin);
}

.page {
  .tickmarks {
    display: flex;
    justify-content: space-between;
    align-items: center;

    content: "";
    height: 5px;
    background: #efefef;
    border-radius: 2.5px;

    p {
      background: #efefef;
      margin: 0;
      padding: 0;
      width: 15px;
      height: 15px;
      border-radius: 50%;
    }
  }

  .spendenElement-big,
  .spendenElement-small {
    #spendenbilder {
      display: flex;
      justify-content: center;

      .img-fluid {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    #spendeninputs {
      display: flex;
      align-items: end;

      > .row {
        width: 100%;

        > div {
          display: flex;
          align-items: center;

          .slidecontainer {
            width: 100%;
          }
        }
      }

      .spendenbetrag {
        width: 80%;
        padding: 5px;
        float: left;
      }

      .euro {
        padding: 5px;
        text-align: center;
      }

      .spendenbetrag,
      .euro {
        @include media-breakpoint-down(xs) {
          margin-top: 2rem;
        }
      }
    }

    input:focus {
      outline: none;
    }

    .slider {
      -webkit-appearance: none;
      --range: calc(var(--max) - var(--min));
      --ratio: calc((var(--val) - var(--min)) / var(--range));
      --sx: calc(.5 * 1.5em + var(--ratio) * (100% - 1.5em));
      margin: 0;
      padding: 0;
      width: 100%;
      height: 1.5em;
      background: transparent;
      font: 1em/1 arial, sans-serif;
      border: none;
    }

    .slider,
    .slider::-webkit-slider-thumb {
      -webkit-appearance: none;
    }

    .slider::-webkit-slider-runnable-track {
      box-sizing: border-box;
      border: none;
      width: 12.5em;
      height: 0.5em;
      background: transparent;
    }

    .js .slider::-webkit-slider-runnable-track {
      background: linear-gradient(#7b1c1a, #7b1c1a) 0/var(--sx) 100% no-repeat #ccc;
    }

    .slider::-moz-range-track {
      box-sizing: border-box;
      border: none;
      height: 0.5em;
      background: transparent;
    }

    .slider::-ms-track {
      box-sizing: border-box;
      border: none;
      width: 12.5em;
      height: 0.5em;
      background: transparent;
    }

    .slider::-moz-range-progress {
      height: 0.5em;
    }

    .slider::-ms-fill-lower {
      height: 0.5em;
    }

    .slider::-webkit-slider-thumb {
      background: url("/global/layout/2019/images/ring.svg");
      background-repeat: no-repeat;
      -webkit-appearance: none;
      border: none;
      height: 51px;
      width: 51px;
      border-radius: 50%;
      cursor: pointer;
      margin-top: -18px;
      /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    }

    .slider::-moz-range-thumb {
      background: url("/global/layout/2019/images/ring.svg");
      background-repeat: no-repeat;
      border: none;
      height: 51px;
      width: 51px;
      border-radius: 50%;
      cursor: pointer;
    }

    .slider::-ms-thumb {
      background: url("/global/layout/2019/images/ring.svg");
      background-repeat: no-repeat;
      border: none;
      height: 51px;
      width: 51px;
      border-radius: 50%;
      cursor: pointer;
    }

    .slider::-ms-tooltip {
      display: none;
    }

    .tickmarks {
      @include tickmarks(18px, 17.5px);
      margin-top: 11px;

      p:not(:first-child):not(:last-child) {
        //dirty fix bis Template-Aktualisierung
        display: none;
      }

      +.slider {
        height: 30px;
      }
    }
  }

  .spendenElement-small {
    background: url(/global/layout/2019/images/hintergrundbilder/spendenelementSmal.jpg) no-repeat scroll 0 0 / cover;
  }

  .spendenElement-big {
    @include media-breakpoint-up(xl) {
      margin-top: 5rem;
    }

    @include media-breakpoint-down(lg) {
      .spPicture-left,
      #spendenbilder,
      .text-right {
        display: none;
      }
    }

    background: url(/global/layout/2019/images/hintergrundbilder/spendenelementBig.jpg) no-repeat scroll 0 0 / cover;

    .spPicture-left {
      max-height: 115%;
      bottom: 0;
      overflow: hidden;

      > img {
        height: 100%;
        width: auto;
        max-width: unset;
      }
    }
  }


  .patenschaft {
    &.single,
    &.multiple,
    &.danke {
      background: url("/global/layout/2019/images/hintergrundbilder/spendenelementBig.jpg") no-repeat scroll 0 0 / cover;

      * {
        transition: all 0.4s ease;
      }

      a:not(.btn) {
        color: $mainColorWhite;
        text-decoration: underline; // sonst nicht von Text unterscheidbar

        &:hover {
          opacity: 0.7;
        }
      }

      .spenden {
        > .row {
          > div {
            margin-bottom: 0.75rem;
          }
        }

        .btn {
          margin: 0;
        }
      }

      .fa-lock-alt {
        font-size: 2rem;
        padding-right: 10px;
      }

      .sum {
        text-align: right;
        font-weight: bold;

        @include media-breakpoint-down(sm) {
          margin-top: 0;
          text-align: center;
          order: -3;
        }

        button.btn.btn-yellow {
          margin-top: 5px;
          font-weight: bold;
          float: right;
        }
      }

      .btn-yellow {
        display: block;
        transition: all 0.5s ease;
      }

      h4 {
        hyphens: none;
      }

      ol {
        padding-left: 2rem;

        li {
          margin-bottom: 0.5rem;
        }
      }

      .output {
        @include media-breakpoint-down(md) {
          margin-top: 0;
        }
      }

      .tickmarks {
        @include tickmarks(18px, 42.5px);
        margin-top: 36px;

        @include media-breakpoint-down(xs) {
          @include tickmarks(12px, 42.5px);
        }
      }
    }

    &.single,
    &.multiple {
      .rangeslider,
      input[type="range"] {
        margin-top: 20px;
        margin-bottom: 30px;

        @include media-breakpoint-down(xs) {
          margin-bottom: 15px;
        }
      }
    }

    &.single {
      .donateval p {
        min-height: 160px;
      }

      .row {
        .auswahl,
        .spenden {
          padding: 0 15px;
        }

        #onlinespende {
          h3 {
            margin-top: 1.5rem;
            margin-bottom: 0;
          }

          .form-group {
            label {
              margin-top: 0.75rem;
            }

            .acInput {
              max-width: 100%;
              width: 100%;
            }
          }
        }

        input:focus {
          outline: none;
        }

        .auswahl {
          padding-top: 50px;
        }

        .spenden {
          padding-top: 15px;
          padding-bottom: 30px;
        }

        .img-fluid {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &.multiple {
      padding: 2rem;

      .anleitung {
        img {
          width: 80%;
          margin: 0 10%;
        }
      }

      .btn-red:disabled[name="submit"],
      .sum>button:disabled {
        color: $mainColorWhite;
        background-color: $mainColorGrey;
        border-color: $mainColorGrey;
        cursor: not-allowed;
        opacity: 1;
      }

      .auswahl {
        border-top: 3px solid $dlrgColorYellow;
        border-bottom: 3px solid $dlrgColorYellow;
        padding: 1rem 0;
        margin: 1rem 0;

        .row {
          padding: 1rem 0;

          > div:first-of-type {
            justify-content: center;
          }

          > div:last-of-type {
            justify-content: end;
          }

          .big {
            font-size: 4rem;
            padding: 0.5rem 0;
          }

          @include media-breakpoint-down(sm) {
            > div {
              justify-content: center;

              h5 {
                text-align: center;
              }
            }
          }

          .btn-yellow {
            min-width: 105px;

            @include media-breakpoint-up(md) {
              output {
                display: flex;
                justify-content: center;
              }
            }

            &.disabled {
              opacity: 1;
            }
          }
        }
      }

      @include media-breakpoint-down(xs) {
        .tickmarks {
          @include tickmarks(12px, 41.5px);
        }
      }
    }

    &.danke {
      .dankeAufteilungList {
        list-style: none;
        padding-left: 1rem;

        i {
          margin-right: 0.25rem;
          width: 2rem;
          text-align: center;
        }
      }
    }

    &#spendenzertifikat {
      .zertifikatThumb {
        max-width: 200px;
        border: 1px solid $mainColorGrey;
      }
    }

    .rangeslider {
      position: relative;

      &__tooltip {
        display: block;
        margin-top: 2.5em;
        font-size: 16px;
        color: #a59eb5;
      }

      &__handle {
        border-radius: 22px;
        line-height: 42px;
        text-align: center;
        font-weight: bold;

        &:after {
          background: none;
        }
      }

      &--horizontal {
        height: 40px;
        width: 100%;
        background: none;
        background-position: center;
        background-repeat: no-repeat;
        box-shadow: none;
        background-size: contain;
      }

      &--disabled {
        opacity: 0.4;
      }

      &__fill {
        position: absolute;
        background: none;
        box-shadow: none;
      }

      &--horizontal &__fill {
        top: 0;
        height: 100%;
      }

      &--vertical &__fill {
        bottom: 0;
        width: 100%;
      }

      &__handle {
        background: transparent;
        background-image: url("/global/layout/2019/images/ring.svg");
        background-repeat: no-repeat;
        background-size: contain;
        box-shadow: none;
        border: none;
        cursor: pointer;
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        line-height: 28px;
        touch-action: pan-y;
        width: 51px;
        height: 51px;
        top: -6px;

        @include media-breakpoint-down(xs) {
          width: 39px;
          height: 39px;
          top: 0;
        }
      }

      &--active &__handle,
      &__handle:active {
        background-image: url("/global/layout/2019/images/ring.svg");
      }
    }

    .rangeslider,
    input[type="range"] {
      max-width: 100%;
      margin: 20px 0;
      background-repeat: no-repeat;
    }

    .rangeslider,
    .rangeslider__fill {
      display: block;
    }
  }
}