.page {
  .news {
    .card-footer {
      border: 0;
      background: none;
    }
  }
}

// News Aktuelles
.page {
  .news {
    &.actual {
      .newsBox {
        position: relative;
        height: 550px;

        .imgTransparent {
          height: 100%;
          width: 100%;
        }
      }

      .img-cont.text-bottom {
        padding: 0 15px;
      }

      .search-input .input-group-btn,
      .btn-group {
        .btn {
          margin-top: 0;
        }
      }
    }
  }

  .teasertext-4-lines {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    hyphens: initial;
  }

  .newsBox {
    .trauerbox {
      .category {
        background: $mainColorWhite;
        color: $mainColorBlack;
      }
    }

    .teaser {
      max-width: 100%;
    }
  }

  .allNews {
    .shariff {
      .orientation-horizontal {
        justify-content: flex-end;
        flex-direction: row;
        align-content: flex-end;
        flex-wrap: inherit;
        max-width: none;
        padding-right: 15px;

        li {
          margin-right: 5px;
        }
      }
    }

    .social-media {
      .shariff {
        .fa {
          padding-top: 0;
        }
      }
    }
  }
}
// ENDE News Aktuelles


// News-Helden (teaserhero)
.page {
  .news-heroes {
  padding-bottom: 60px;

  .carousel-inner {
    width: 95%;
    margin: 0 auto;

    .text {
      min-height: 6rem;
    }

    .image {
      @include media-breakpoint-up(md) {
        &.bgBlue {
          max-height: 400px;
          overflow: hidden;

          .img-fluid {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }

      @include media-breakpoint-down(md) {
        &.bgBlue {
          .img-fluid {
            width: 100vw;
            height: calc(100vw / 16 * 9);
            object-fit: cover;
          }
        }
      }

      .img-cont.text-bottom {
        margin-left: 1rem;
      }
    }
  }

  .carousel-indicators {
    margin: 0 15% -5px 15%;
  }

  .carousel-control-prev,
  .carousel-control-next {
    opacity: 1;
    width: 2.5%;
  }

  .row>div:nth-child(1) {
    margin-bottom: 1rem;
  }
}
}
// ENDE News-Helden (teaserhero)


// Erfolgsgeschichte (teaserstory)
.page {
  .news.successStories {
    .imgTransparent {
      opacity: 0.7;
    }

    .img-fluid {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }

    .fa-chevron-left,
    .fa-chevron-right {
      color: $mainColorWhite !important;
    }

    .carousel-control-prev,
    .carousel-control-next {
      width: 4%;
      background: transparent;
      opacity: 1;
      z-index: 999;
      margin-top: -50px;
    }

    .carousel-indicators {
      left: 0;
      margin: 0 15% -50px 15%;
    }

    .carousel-inner {
      width: 92%;
      padding: 25px 0;
      margin: 0 auto;

      @include media-breakpoint-up(lg) {
        .card-img-top {
          height: 15.8vW;
          object-fit: cover;
        }
      }
    }
  }
}
// ENDE Erfolgsgeschichte (teaserstory)


// Teaser Stapel (teaserstack)
.page {
  .news .newsStack {
    @include media-breakpoint-down(sm) {
      // wenn ein Element pro Zeile angezeigt wird
      .row.no-gutters>div {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
}
// ENDETeaser Stapel (teaserstack)


// Carousel (carousel)
.page {
  .news-carousel {
    .carousel {
      margin-bottom: 4rem;
    }

    .carousel-caption {
      width: 80%;

      p {
        width: 50%;
      }

      @include media-breakpoint-down(md) {
        width: calc(100% - 20px);
        top: 30%;

        p {
          width: unset;
        }
      }
    }

    .carousel-item {
      height: 32rem;
      background-color: #777;

      >img {
        position: absolute;
        top: 0;
        left: 0;
        min-width: 100%;
        height: 32rem;
        object-fit: cover;
      }
    }

    .marketing .col-lg-4 {
      margin-bottom: 1.5rem;
      text-align: center;
    }

    .marketing h2 {
      font-weight: 400;
    }

    .marketing .col-lg-4 p {
      margin-right: .75rem;
      margin-left: .75rem;
    }


    .featurette-divider {
      margin: 5rem 0;
    }

    .featurette-heading {
      font-weight: 300;
      line-height: 1;
      letter-spacing: -.05rem;

      @include media-breakpoint-up(md) {
        font-size: 50px;
      }
    }

    div.youtube_container {
      position: relative;
      height: 32rem;
      padding-bottom: 50%;
    }

    .carousel-caption p {
      margin-bottom: 1.25rem;
      font-size: 1.15rem;
      line-height: 1.4;
    }
  }
}
// ENDE Carousel (carousel)


// Tabelle (table)
.page {
  .news-table {
    margin-bottom: 0.5rem;
  }
}
// ENDE Tabelle (table)


// Zeitleiste (timeline)
.page {
  .newsTimeline {
    .youtube_container {
      position: relative;
      height: 0;
      padding-bottom: 56.25%; // Seitenverhältnis 16/9
    }

    .showOnly2Lines {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      hyphens: initial;
    }

    .timeline {
      line-height: 1.4em;
      list-style: none;
      padding: 0;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        line-height: inherit;
      }
    }

    .timeline-item {
      padding-left: 40px;
      position: relative;
    }

    .timeline-info {
      font-size: 0.8rem;
      font-weight: 700;
      letter-spacing: .1rem;
      margin-bottom: 0.5em;
      text-transform: uppercase;
      white-space: nowrap;
    }

    .timeline-poolSource {
      font-size: 1rem;
    }

    .timeline-marker {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }

    // Rote Bubbles
    .timeline-marker:before {
      background: $dlrgColorRed;
      border: 3px solid transparent;
      border-radius: 50%;
      content: "";
      height: 15px;
      position: absolute;
      top: 4px;
      left: 0;
      width: 15px;
      transition: background 0.15s ease-in-out, border 0.15s ease-in-out;
    }

    // Graue Linie
    .timeline-marker:after {
      content: "";
      width: 3px;
      background: $mainColorGrey;
      display: block;
      position: absolute;
      top: 24px;
      bottom: 0;
      left: 6px;
    }

    .timeline-item {
      &:not(.period):hover .timeline-marker:before {
        background: $dlrgColorYellow;
        border: 3px solid $dlrgColorRed;
      }

      &:last-child .timeline-marker:after {
        content: none;
      }
    }

    .timeline-img {
      max-width: 100%;
      margin-left: 0.5em;
      margin-top: 2rem;
    }

    .timeline-content {
      padding-bottom: 40px;

      p:last-child {
        margin-bottom: 0;
      }
    }

    // Jahrestitel
    .period {
      padding: 0;

      .timeline-marker:before {
        background: transparent;
        width: 15px;
        height: auto;
        border: none;
        border-radius: 0;
        top: 0;
        bottom: 30px;
        position: absolute;
        border-top: 3px solid $mainColorGrey;
        border-bottom: 3px solid $mainColorGrey;
      }

      &:first-child .timeline-marker:before {
        border-top: none;
      }

      .timeline-marker:after {
        height: 32px;
        top: auto;
      }

      .timeline-content {
        padding: 40px 0 70px;
      }

      .timeline-title {
        margin: 0;
      }
    }

    @include media-breakpoint-up(lg) {

      .timeline {
        .timeline-item,
        .timeline-info,
        .timeline-marker,
        .timeline-content {
          display: block;
          margin: 0;
          padding: 0;
        }

        .timeline-item {
          padding-bottom: 50px;
          overflow: hidden;
        }

        .timeline-marker {
          position: absolute;
          left: 50%;
          margin-left: -7.5px;
        }

        .timeline-info,
        .timeline-content {
          width: 50%;
        }

        &>.timeline-item:nth-child(odd) .timeline-content,
        &>.timeline-item:nth-child(even) .timeline-info {
          float: right;
          text-align: left;
          padding-left: 40px;
        }

        &>.timeline-item:nth-child(even) .timeline-content,
        &>.timeline-item:nth-child(odd) .timeline-info {
          float: left;
          text-align: right;
          padding-right: 40px;

          .timeline-img {
            margin-left: auto; //wenn Breite angegeben, Bilder trotzdem rechts anordnen
          }
        }

        &>.timeline-item.period .timeline-content {
          float: none;
          padding: 0;
          width: 100%;
          text-align: center;
        }

        .timeline-item.period {
          padding: 50px 0 70px;

          .timeline-marker:after {
            bottom: 0;
            top: auto;
          }

          .timeline-title {
            left: auto;
          }
        }
      }
    }
  }
}
// ENDE Zeitleiste (timeline)


// Detailseite (detail)
.page {
  .news {
    .news-downloads {
      padding: 25px 0;

      a {
        font-size: 1.0rem;
      }
    }

    .news-items-details {
      a {
        font-size: 1.0rem;
      }
    }
  }

  .news-detail {
    .news-banner {
      top: 0;
      width: 100%;

      .dlrg-label {
        padding: 5px 10px;
        max-width: max-content;
      }

      .dlrg-label,
      .social-media {
        top: 0;
      }

      .shariff {
        ul {
          float: right;
          width: 120px;
        }

        li a {
          color: $mainColorWhite !important;
        }
      }
    }

    .img-fluid {
      min-width: 100%;
      height: 400px;
      max-height: 400px;
      object-fit: cover;
    }

    .textContent {
      width: 100%;
    }

    .teaser:not(.no-pic) small a {
      color: $mainColorWhite !important;
    }

    .file-size {
      font-size: 0.9rem;
    }

    .img-news {
      max-width: 100%;
      font-size: 85%;
      padding-bottom: 10px;
    }

    .youtube_container {
      position: relative;
      height: 400px;
      max-height: 400px;

      .youtube_video {
        position: absolute;
        top: 0;
        min-width: 100%;
        height: 100%;
      }
    }

    .news-media {
      max-width: 100%;

      @include media-breakpoint-down(md) {
        max-width: 50%;
      }

      @include media-breakpoint-down(sm) {
        &,
        .imgWidth {
          max-width: 100%;
          width: 100% !important; // dass Inline-Wert ueberschrieben wird!
        }
      }

      .images {
        max-width: 100%;
        width: 100%;
        height: auto;

        img {
          width: 100%;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      .newsartikel {
        display: flex;
        flex-direction: column;

        .news-media.float-right:only-of-type {
          order: +9999;
          margin-left: 0 !important; // dass margin von ml-4 ueberschrieben wird
        }
      }
    }
  }
}
// ENDE Detailseite (detail)