.page {
  .divider_picText {
    a {
      &:not(.btn) {
        color: $mainColorWhite;
        text-decoration: underline; // sonst nicht von Text unterscheidbar

        &:hover {
          opacity: 0.7;
        }
      }

      &.btn {
        margin-top: calc(2.1875rem / 3);
        margin-bottom: 2.1875rem;
      }
    }

    .bgBlue {
      overflow: hidden; //Versteckt überstehendes Bild, wenn Text kürzer als Bild hoch.
    }

    .container {
      position: relative;

      .social-media {
        position: absolute;
        top: 0.25rem;
        right: 0.5rem;
        z-index: 10;
      }
    }

    .img-fluid {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }

    .imgTransparent {
      opacity: 0.7;
    }

    .textContent {
      top: 0;
      width: 100%;
      position: relative;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 4rem;
      margin-bottom: 4rem;
    }

    .openCloseHoverbox {
      width: 50px;
      border-width: 2px !important; //important, weil Bootstrap schon important
      padding: 0.5rem 1.5rem !important; //important, weil Bootstrap schon important
      cursor: pointer;

      .plus {
        position: relative;
        left: 0;
        top: 15px;
        width: 30px;
        height: 30px;

        .line {
          width: 2px;
          height: 100%;
          top: -50%;
          position: absolute;
          margin-left: -1px;
          border: 0;
        }

        .linep {
          height: 2px;
          width: 100%;
          top: 0%;
          left: -50%;
          position: absolute;
          margin-top: -1px;
        }

        &.active .line {
          display: none;
        }
      }
    }

    .hoverbox {
      display: none;
      text-align: center;
    }

    @include media-breakpoint-up(xl) {
      .container .social-media {
        top: 1.5rem;
        right: 1rem;
      }

      .textContent {
        margin-top: 6rem;
        margin-bottom: 6rem;
      }
    }

    &.newsletter {
      .textContent {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
      }

      a.btn {
        margin-bottom: 0;
      }

      .imgTransparent {
        opacity: 0.2;
      }

      @include media-breakpoint-up(xl) {
        .textContent {
          margin-top: 3rem;
          margin-bottom: 3rem;
        }
      }
    }
  }
}