.page {
  .trauer {
    &.borderBlack {
      border: 5px solid $mainColorBlack;
      padding: 0.75rem;
    }

    .schwarzesBand {
      height: 25px;
      width: 200px;
      position: absolute;
      top: 45px;
      z-index: 50;

      &.posBand-links {
        rotate: -45deg;
        left: -45px;
      }

      &.posBand-rechts {
        rotate: 45deg;
        right: -45px;
      }
    }

    .img-cont {
      .blackBox,
      .whiteBox {
        width: fit-content;
        padding: 0 .75rem;
      }

      .whiteBox {
        margin-top: 0.5rem;
        margin-left: 1rem;
        color: $mainColorGrey;
      }
    }
  }
}