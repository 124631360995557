.modal {
  .modal-dialog,
  .modal-content {
    max-height: calc(100vH - 1rem);
  }

  .modal-dialog {
    display: flex;
  }

  .modal-dialog {
    overflow: hidden;
  }

  .modal-body {
    overflow-y: auto;
  }

  @include media-breakpoint-up(sm) {
    .modal-dialog,
    .modal-content {
      max-height: calc(100vH - 3.5rem);
    }
  }
}